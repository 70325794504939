import { documentToReactComponents, NodeRenderer, Options } from "@contentful/rich-text-react-renderer"
import { Document, INLINES } from "@contentful/rich-text-types"

const AssetLink: NodeRenderer = (node, children) => (
  <a href={node.data["target"].fields.file["fr-FR"].url}>{children}</a>
)
const Hyperlink: NodeRenderer = (node, children) => (
  <a href={node.data["uri"]} target="_blank" rel="noopener">
    {children}
  </a>
)

const OPTIONS: Options = {
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: AssetLink,
    [INLINES.HYPERLINK]: Hyperlink,
  },
}

interface Props {
  node: {
    json: Document
  }
}

// https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/

const RichText: React.FC<Props> = ({ node }) => (node ? <>{documentToReactComponents(node.json, OPTIONS)}</> : null)

export default RichText
